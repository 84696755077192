<template>
  <ul class="menu-nav">
    <router-link
      to="/home"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-2"></i>
          <span class="menu-text">Inicio</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/xxx"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cup"></i>
          <span class="menu-text">Superhéroes destacados</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/xxx"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-comment"></i>
          <span class="menu-text">Mensajes</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/xxx"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-infographic"></i>
          <span class="menu-text">Sala de batalla</span>
        </a>
      </li>
    </router-link> -->

    <li class="timer-widget">
      <img :src="require('@/assets/images/clock.png')" class="clock">
      <p class="description" v-if="getRecordingStatus < 2">
      Dedica <b>10 minutos</b> diarios para aprender, descubrir y crecer. Diviértete mientras aprendes.</p>
      <p class="description" v-if="getRecordingStatus >= 2">
        ¡Felicidades! Han pasado<br> <b>10 minutos</b> <br>
        Sigue divirtiéndote o detente cuando quieras
      </p>
      <v-icon color="#62CC69" size="50" v-if="getRecordingStatus >= 2">mdi-check</v-icon>
      <p class="showtimeline" @click="onShowDayTrack">
       <v-icon color="#007CC2">mdi-calendar-range</v-icon> Ver últimos 10 días de tu actividad
      </p>
    </li>

    <li>
      <div class="logout-action" @click="onLogout">
        <span class="material-icons mr-3 mb-0">
        meeting_room
        </span>
        <h4 class="mr-7 mb-0">Logout</h4>
      </div>
    </li> 
    <li>
      <v-dialog v-model="showDaysTrack"
      width="auto">
        <v-card>
            <v-toolbar
                dark
                color="#009BDB"
            >
              <v-card-title class="days-title">
              Tus últimos 10 días de actividad
              </v-card-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="showDaysTrack = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div class="days-result-content">
              <div class="days">
                <div class="day-items" v-for="(day, key, index) in dayTracks" :key = "key + index">
                  <img :src="require('@/assets/images/remove.png')" v-if = "day.status == 'not_joined'">
                  <img :src="require('@/assets/images/cancel.png')" v-if = "day.status == 'not_completed'">
                  <img :src="require('@/assets/images/check.png')" v-if = "day.status == 'completed'">
                  <p>
                    {{day.date}}
                  </p>
                </div>
              </div>
              <v-card-text class="d-block">
                <div>
                  <img :src="require('@/assets/images/check.png')">
                  Tiempo objetivo cumplido: 10 minutos
                </div>
                <div>
                  <img :src="require('@/assets/images/cancel.png')">
                  Tiempo objetivo <b>NO</b> cumplido: 10 minutos
                </div>
                <div>
                  <img :src="require('@/assets/images/remove.png')">
                  No entraste ese día
                </div>
              </v-card-text>
          </div>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="showTimer"
        width="auto"
      >
        <v-card>
            <v-toolbar
                dark
                color="#009BDB"
              >
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="showTimer = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="timer-result">
              <div class="lion">
                <img :src="require('@/assets/images/leon.png')">
              </div>
              <div class="description">
                <p class="title">
                  ¡Felicidades!
                </p>
                <p>
                  Han pasado <b>10 minutos.</b>
                </p>
                <p>
                  Sigue divirtiéndote o detente cuando <br>quieras
                </p>
                <div class="status d-flex align-items-center">
                  <img :src="require('@/assets/images/clock.png')">
                  <v-icon color="#62CC69" size="50">mdi-check</v-icon>
                  <v-spacer></v-spacer>
                  <v-btn variant="flat" size="x-large" 
                  color="#62CC69" 
                  class="ml-4 text-white"
                  style="font-size: 16px;padding: 20px;text-transform: capitalize;"
                  @click="showTimer = false"
                    >
                    Continuar
                  </v-btn>
                </div>
              </div>
            </div>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="showInitTimer"
        width="auto"
      >
        <v-card>
            <v-toolbar
                dark
                color="#009BDB"
              >
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="showInitTimer = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <div class="timer-result">
              <div class="lion">
                <img :src="require('@/assets/images/leon.png')">
              </div>
              <div class="description">
                <p class="title">
                  ¡Bienvenido a Fastmind!
                </p>
                <p>
                  Dedica mínimo 
                </p>
                <p>
                  10 minutos - 4 veces a la semana 
                </p>
                <div class="status d-flex align-items-center">
                  <img :src="require('@/assets/images/clock.png')">
                  <v-spacer></v-spacer>
                  <v-btn variant="flat" size="x-large" 
                  color="#62CC69" 
                  class="ml-4 text-white"
                  style="font-size: 16px;padding: 20px;text-transform: capitalize;"
                  @click="showInitTimer = false"
                    >
                    ¡Comenzar!
                  </v-btn>
                </div>
              </div>
            </div>
        </v-card>
      </v-dialog>
      
    </li>
  </ul>


</template>

<style scoped>

.days-result-content .d-block {
  flex-flow: column;
  display: flex!important;
  padding-left: 35px;
}

.days-result-content .d-block div{
  margin-bottom: 8px;
  font-size: 14px;
}

.days-result-content .d-block div img {
  margin-right: 16px;
}

.logout-action{
    height: 50px;
    width: 200px;
    background: #FFE4E6;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
}
.menu-item.menu-item-active > .menu-link {
    background-color: #E0F2FE !important;
    opacity: 1 !important;
}
.menu-item>.menu-link {
    opacity: 0.4 !important;
}
.menu-text{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 15px;
  /* identical to box height, or 100% */
  color: #0B2343 !important;
}
.aside-menu .menu-nav > .menu-item {
  background-color: #F0F9FF !important;
  margin-bottom: 3px;
}
.aside-menu .menu-nav > .menu-item .menu-link {
  padding: 20px;
}
.menu-item :hover{
   background-color: #E0F2FE !important;
}

.timer-widget {
  padding: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.timer-widget > .description {
  text-align: center;
  margin-top: 16px;
}

.timer-widget > .showtimeline {
  text-align: center;
  color: #007CC2;
  margin-top: 8px;
  font-size: 12px;
  cursor: pointer;
}

.timer-result {
  display: flex;
}

.timer-result > .description {
  padding: 20px;
  font-size: 16px;
}

.timer-result > .description > .title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #FF6A6A;
}

.days-title {
  color: #FFE600
}

.days-result-content .days {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-bottom: 1px #D9D9D9 solid;
  overflow-y: auto;
}

.days-result-content .days .day-items {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  margin: 8px;
}

.days-result-content .days .day-items img {
  width: 25px;
  height: 25px;
}

.days-result-content .days .day-items p {
  width: 50px;
  text-align: center;
}
</style>
<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import { mapGetters } from "vuex";

export default {
  name: "KTMenu",
  data() {
    return {
      showTimer: false,
      showDaysTrack: false,
      showInitTimer: false,
      dayTracks: {}
    }
  },
  created() {
    var tvalue = JwtService.getTimer();
    if (!tvalue) {
      this.showInitTimer = true;
      JwtService.setTimer();
    } 
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    onShowTimer() {
      this.showTimer = true;
    },
    onInitTimer() {
      this.showInitTimer = true;
    },
    onShowDayTrack() {
      this.showDaysTrack = true;

      this.dayTracks = {};
      for (let i = 1; i <=10; i++) {

        let status = 'not_joined'

        for (let j of this.currentUser.records) {
          if (j.date.toString() == this.getFormattedDateDaysAgo(i)) {
            if (j.time > 0) {
              status = 'not_completed';
            }

            if (j.time >= 600) {
              status = 'completed';
            }
            break;
          }
        }

        this.dayTracks[this.getFormattedDateDaysAgo(i)] = {
          status: status,
          date: i == 1? `Hace 1 día`:`Hace ${i} días`,
          id: this.getFormattedDateDaysAgo(i) + Date()
        };
      }
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    getFormattedDateDaysAgo(days) {
      const date = new Date();
      date.setDate(date.getDate() - days);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${month}${day}`;
    }
  },
watch: {
  getRecordingStatus(newValue) {
    if (newValue == 2) {
      this.onShowTimer();
    }
  }
},
computed: {
    ...mapGetters([
        "getRecordingStatus",
        "currentUser"
    ]),
   }
};
</script>
